import _ from "lodash";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Avatar from 'react-avatar';
import { connect } from 'react-redux';
import { Collapse } from 'reactstrap';
import { UserDatabaseConnector } from '../../dataconnector/usersDatabaseConnector';
import { getZooList } from '../../tools/conf';

const uConnect = new UserDatabaseConnector()

class SidebarUserBlock extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showUserBlock: true,
            dropDown: false
        }
    }


    toggleDropDown = () => {
        this.setState({ dropDown: !this.state.dropDown })
    }

    handleZooChange = async (zoo) => {
        let userData = this.state
        let showUserBlock = this.state.showUserBlock

        delete userData.showUserBlock
        delete userData.dropDown
        delete userData.zooList

        userData.zooId = zoo.value
        userData.userZoo = zoo

        let res = await uConnect._updateTempUserData(userData)

        if (res.success) {
            let filteredZooList = []
            getZooList().map(zoo => {
                if (zoo.value !== res.data.zooId) {
                    filteredZooList.push(zoo)
                }
            })

            this.setState({
                ...res.data,
                showUserBlock: showUserBlock,
                zooList: filteredZooList,
                dropDown: false
            })
        }
    }

    componentDidMount = async () => {
        try {
            let user = await uConnect._getUserData()
            let zooList = getZooList()
            let filteredZooList = []
            zooList.map(zoo => {
                if (zoo.value !== user.data.zooId) {

                    filteredZooList.push(zoo)
                }
            })
            this.setState({
                ...user.data,
                zooList: filteredZooList
            })
        } catch (error) {
            console.log(error)
        }

    }

    componentWillReceiveProps(newProps) {
        if (newProps.showUserBlock !== this.props.showUserBlock) {
            this.setState({ showUserBlock: newProps.showUserBlock })
        }
    }

    render() {
        return (
            <Collapse id="user-block" isOpen={this.state.showUserBlock}>
                <div>
                    <div className="item user-block">
                        {/* User picture */}
                        <div className="user-block-picture">
                            <div className="user-block-status">
                                {_.has(this.state.userProfilePicture, 'url') ? <img className="img-thumbnail rounded-circle" src={this.state.userProfilePicture.url} alt="Avatar" width="60" height="60" /> : <Avatar size="60" round={true} name={this.state.userFirstname + " " + this.state.userLastname} />}
                            </div>
                        </div>
                        {/* Name and Job */}
                        <div className="user-block-info">
                            {_.has(this.state, "userFirstname") ? <span className="user-block-name">{this.state.userFirstname}</span> : null}
                            {_.has(this.state, "userJob") ? <span className="user-block-role">{this.state.userJob}</span> : null}

                        </div>
                    </div>
                </div>
            </Collapse>
        )
    }
}

SidebarUserBlock.propTypes = {
    showUserBlock: PropTypes.bool
};

const mapStateToProps = state => ({ showUserBlock: state.settings.showUserBlock })

export default connect(
    mapStateToProps
)(SidebarUserBlock);