
import { initDb } from '../tools/conf';

export class syncManager {
    async _handleMassSync() {
        let url = "https://europe-west3-aws-prod-3fac0.cloudfunctions.net/syncBack"
        try {
            let res = await fetch(
                url,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    method: "POST",
                    body: JSON.stringify({ key: "41F76BD88C2B3873478E4F7AB5CD7" })
                }
            )

            let response = await res.json()
            if (response.success) {
                let data = response.data
                await this.uploadSpecies(data.species)
                await this.uploadFamilies(data.families)
                await this.uploadOrders(data.orders)
                await this.uploadQuestions(data.questions)
            }


            // species: species,
            // families: families,
            // orders: orders,
            // questions: questions,
            // surveys: surveys,
            // obs: obs,




            // await this.uploadObs(data.species)
            // await this.uploadSurvey(data.species)


            return { success: true, data: null }
        } catch (error) {
            console.log("error")
            console.log(error)
            return { success: false, data: error }
        }
    }


    async uploadSpecies(array) {
        let db = initDb()
        await Promise.all(array.map(async specie => {
            console.log(specie.specieName)
            db.collection("species").doc(specie.specieId).set(specie)
        }))
        return null
    }
    async uploadFamilies(array) {
        let db = initDb()
        await Promise.all(array.map(async family => {
            console.log(family.familyName)
            db.collection("family").doc(family.familyId).set(family)
        }))
        return null
    }
    async uploadOrders(array) {
        let db = initDb()
        await Promise.all(array.map(async order => {
            db.collection("order").doc(order.orderId).set(order)
        }))
        return null
    }
    async uploadQuestions(array) {
        let db = initDb()
        await Promise.all(array.map(async question => {
            console.log(question.questionRef)
            db.collection("questions").doc(question.questionId).set(question)
        }))
        return null
    }
    // async uploadObs(array) {
    //     await Promise.all(array.map(async obs => {
    //         console.log(obs)
    //     }))
    //     return null
    // }
}