
import { v4 as uuidv4 } from 'uuid';
import { animalGenerator } from '../../AnimalManagement/demo/animalGenerator';
const { uniqueNamesGenerator, adjectives, colors, animals, names } = require('unique-names-generator');
const animalGen = new animalGenerator()


export class FakeSiteGenerator {
    constructor() {

    }

    _getAnimalsList() {
        let animalArray = []

        let max = Math.floor(Math.random() * (9))
        let i
        for (i = 0; i <= max; i++) {
            animalArray.push({
                animalName: uniqueNamesGenerator({ dictionaries: [names] }),
            })
        }
        return animalArray
    }

    _getEnclosuresListAlt() {

        let speciesList = animalGen._getSpecieListDemo()
        // let speciesList = [1, 2, 3]

        let enclosureList = []
        let i
        let amount = 3

        for (i = 0; i < amount; i++) {
            let id = uuidv4()
            enclosureList.push({
                enclosureName: `Volière - ${i + 1}`,
                label: `Volière - ${i + 1}`,
                value: `Volière - ${i + 1}`,
                enclosureId: `Volière - ${i + 1}`,
                speciesList: [speciesList[0]],
                animalsData: this._getAnimalsList(),
                indoorArea: 100,
                outdoorArea: 800
            })
        }

        i = 0

        for (i = 0; i < amount; i++) {
            enclosureList.push({
                enclosureName: `Batiment - ${i + 1}`,
                enclosureId: `Batiment - ${i + 1}`,
                label: `Batiment - ${i + 1}`,
                value: `Batiment - ${i + 1}`,
                speciesList: [speciesList[1]],
                animalsData: this._getAnimalsList(),
                indoorArea: 100,
                outdoorArea: 800
            })
        }

        i = 0

        for (i = 0; i < amount; i++) {
            enclosureList.push({
                enclosureName: `Enclos - ${i + 1}`,
                enclosureId: `Enclos - ${i + 1}`,
                label: `Enclos - ${i + 1}`,
                value: `Enclos - ${i + 1}`,
                speciesList: [speciesList[2], speciesList[1]],
                animalsData: this._getAnimalsList(),
                indoorArea: 100,
                outdoorArea: 800,
            })
        }

        return enclosureList
    }

    _getEnclosuresList() {

        let enclosureList = []
        let i
        let amount = 3

        for (i = 0; i < amount; i++) {
            let id = uuidv4()
            enclosureList.push({
                enclosureName: {
                    label: `Volière - ${i + 1}`,
                    value: `Volière - ${i + 1}`
                },
                label: `Volière - ${i + 1}`,
                value: `Volière - ${i + 1}`,
                enclosureId: `Volière - ${i + 1}`,
                speciesList: [],
                animalsData: []
            })
        }

        i = 0

        for (i = 0; i < amount; i++) {
            enclosureList.push({
                enclosureName: {
                    label: `Batiment - ${i + 1}`,
                    value: `Batiment - ${i + 1}`
                },

                enclosureId: `Batiment - ${i + 1}`,
                label: `Batiment - ${i + 1}`,
                value: `Batiment - ${i + 1}`,
                speciesList: [],
                animalsData: []
            })
        }

        i = 0

        for (i = 0; i < amount; i++) {
            enclosureList.push({
                enclosureName: {
                    label: `Enclos - ${i + 1}`,
                    value: `Enclos - ${i + 1}`
                },
                enclosureId: `Enclos - ${i + 1}`,
                label: `Enclos - ${i + 1}`,
                value: `Enclos - ${i + 1}`,
                speciesList: [],
                animalsData: []
            })
        }

        return enclosureList
    }

    _getUserList() {
        let i
        let random = Math.floor(Math.random() * 12)
        let amount = 6 + random
        let emptyArray = []
        for (i = 0; i < amount; i++) {
            emptyArray.push({
                userFirstname: uniqueNamesGenerator({ dictionaries: [names] }),
                userLastname: uniqueNamesGenerator({ dictionaries: [names] }),
                userProfilePicture: false,
                userId: uuidv4()
            })
        }
        return emptyArray
    }
}
