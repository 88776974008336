export const CHANGE_ZOOID = 'CHANGE_ZOOID';
export const CHANGE_ZOONAME = 'CHANGE_ZOONAME';
export const TOGGLE_ANONYMOUS = 'TOGGLE_ANONYMOUS';
/**
 * Change a setting value
 * payload.name: name of the setting prop to change
 * payload.value: new value to apply
 */
export function changeZooId(name, value) {
    return { type: CHANGE_ZOOID, name, value };
}

export function changeZooName(name, value) {
    return { type: CHANGE_ZOONAME, name, value };
}

export function toggleAnonymous(value) {
    return { type: TOGGLE_ANONYMOUS, value };
}




