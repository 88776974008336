import { akongoGlobalDatabaseConnector } from "../../../../dataconnector/akongoGlobalDatabaseConnector"
import { v4 as uuidv4 } from 'uuid';
import moment from "moment"
import { FakeSiteGenerator } from "../../SiteManagement/demo/FakeSiteGen";




const { uniqueNamesGenerator, adjectives, colors, animals, names } = require('unique-names-generator');


export class animalGenerator {
    constructor() {
        this.speciesList = null;
        this.enclosuresList = null;
    }

    _getSpecieListDemo() {
        return [
            {
                specieId: "001",
                specieName: "Zèbre",
                specieLatinName: "Equus grevyi",
                value: "001",
                surveyId: "01fb1d81-f6e8-4181-9c2e-36ee101430ea",
                label: "Zèbre"
            },
            {
                specieId: "002",
                specieName: "Capucin",
                specieLatinName: "Cebus capucinus",
                value: "002",
                surveyId: "05fc27db-448e-4b44-9946-ee375177ac73",
                label: "Capucin"
            },

            {
                specieId: "003",
                specieName: "Ibis huppé",
                specieLatinName: "Lophotibis cristata",
                surveyId: "806f8819-a3ad-4931-a779-43146adc40a1",
                value: "003",
                label: "Ibis huppé"
            }
        ]
    }

    async _generateAnimals(amount) {
        this.speciesList = this._getSpecieListDemo()
        const FakeSiteGen = new FakeSiteGenerator
        this.enclosuresList = FakeSiteGen._getEnclosuresList()

        let animalList = []
        let i

        for (i = 0; i < amount; i++) {
            animalList.push(this._getAnimalTemplateWithRandomValue())
        }

        return animalList
    }

    _getFakeComparisonList = async (animalData) => {
        let comparisonList = []

        comparisonList.push({
            label: `Pas de comparaison`,
            value: null,
            type: null
        })

        comparisonList.push({
            label: `Moyenne Francaise - ${animalData.specieName} `,
            value: "fr",
            type: "not null"
        })

        comparisonList.push({
            label: `Moyenne Européene - ${animalData.specieName}`,
            value: "europe",
            type: "not null"
        })

        let i
        for (i = 0; i < 14; i++) {
            let data = `${uniqueNamesGenerator({ dictionaries: [names] })} - ${animalData.specieName}`
            comparisonList.push({
                label: data,
                value: data,
                type: "not null"
            })

        }

        return comparisonList
    }

    getRandomSpecie() {
        const rndInt = Math.floor(Math.random() * (this._getSpecieListDemo().length))
        return this._getSpecieListDemo()[rndInt]
    }

    getRandomEnclosure() {

        const rndInt = Math.floor(Math.random() * (this.enclosuresList.length))
        return this.enclosuresList[rndInt]
    }

    _getChipNumber() {
        let chip = []
        let i
        for (i = 0; i < 14; i++) {
            chip.push(Math.floor(Math.random() * 9))
        }
        let res = chip.join('')
        return res
    }

    _getRandomSex() {
        let i = Math.random()
        if (i >= 0.5) {

            return {
                label: "Mâle",
                value: "Male"
            }
        } else {
            return {
                label: "Femelle",
                value: "Femelle"
            }
        }
    }

    _getLastEval() {
        let now = moment()
        let deltaYear = Math.floor(Math.random() * 12)
        now.subtract(deltaYear, "months")
        return now.unix()
    }

    _getRandomAge() {
        let now = moment()
        let deltaYear = Math.floor(Math.random() * 13) + 1
        now.subtract(deltaYear, "years")
        return now.format("MM/YYYY")
    }

    _getAnimalTemplateWithRandomValue() {
        let newAnimal =
        {
            ...this.getRandomSpecie(),
            animalName: uniqueNamesGenerator({ dictionaries: [names] }),
            animalChipId: this._getChipNumber(),
            animalBirthdate: this._getRandomAge(),
            animalSexe: this._getRandomSex(),
            animalEnclosureData: this.getRandomEnclosure(),
            lastEval: this._getLastEval(),
            animalId: uuidv4()
        }
        return newAnimal
    }
}



