import { CHANGE_ZOOID, CHANGE_ZOONAME, TOGGLE_ANONYMOUS } from '../actions/actions';

const initialSettings = {
    /* Layout fixed. Scroll content only */
    zooId: "akongo",
    zooName: "Environnement de test",
    anonymousMode: false,
};

const zooReducer = (state = initialSettings, action) => {
    switch (action.type) {
        case CHANGE_ZOOID:
            return {
                ...state,
                zooId: action.value
            };
        case CHANGE_ZOONAME:
            return {
                ...state,
                zooName: action.value
            };
        case TOGGLE_ANONYMOUS:

            console.log("step 2")
            console.log(action)

            return {
                ...state,
                anonymousMode: action.value
            };
        default:
            return state;
    }
}

export default zooReducer;