import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as actions from "../../store/actions/actions"
import ToggleFullscreen from "../Common/ToggleFullscreen"
import HeaderRun from "./Header.run"
import { withNamespaces } from "react-i18next"
import Swal from "sweetalert2"
import { UserDatabaseConnector } from "../../dataconnector/usersDatabaseConnector"
import { syncManager } from "../../dataconnector/syncManager"

let sync = new syncManager()

class Header extends Component {
  componentDidMount() {
    HeaderRun()
  }

  toggleUserblock = (e) => {
    e.preventDefault()
    this.props.actions.toggleSetting("showUserBlock")
  }

  toggleOffsidebar = (e) => {
    e.preventDefault()
    this.props.actions.toggleSetting("offsidebarOpen")
  }

  toggleCollapsed = (e) => {
    e.preventDefault()
    this.props.actions.toggleSetting("isCollapsed")
    this.resize()
  }

  toggleAside = (e) => {
    e.preventDefault()
    this.props.actions.toggleSetting("asideToggled")
  }

  handleDisconnect = async (e) => {
    const { t } = this.props
    Swal.fire({
      title: t("common.DISCONNECT"),
      showCancelButton: true,
      confirmButtonText: `Oui`,
      cancelButtonText: t("common.CANCEL"), // Add i18n for cancel button
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let uConnect = new UserDatabaseConnector()
        await uConnect._disconnectUser()
        await uConnect._RemoveRememberMe()
        window.location = "login"
      }
    })
  }

  handleSync = async () => {
    Swal.fire({
      icon: "info",
      title: "Merci de patienter",
      html: "Synchronisation de masse en cours",
      allowOutsideClick: false,
      showCancelButton: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading()
      },
    })
    let res = await sync._handleMassSync()

    console.log(res)
    Swal.fire({
      title: "Mass sync terminée",
    })
  }

  resize() {
    // all IE friendly dispatchEvent
    var evt = document.createEvent("UIEvents")
    evt.initUIEvent("resize", true, false, window, 0)
    window.dispatchEvent(evt)
    // modern dispatchEvent way
    // window.dispatchEvent(new Event('resize'));
  }

  render() {
    return (
      <header className='topnavbar-wrapper'>
        {/* START Top Navbar */}
        <nav className='navbar topnavbar'>
          {/* START navbar header */}
          <div className='navbar-header'>
            <a className='navbar-brand' href='#/'>
              <div className='brand-logo'>
                <img className='img-fluid' style={{ maxWidth: 200 }} src='img/WELFARE-MANAGER_LOGO_HEADER.png' alt='App Logo' />
              </div>
              <div className='brand-logo-collapsed'>
                <img className='img-fluid' src='img/logo-single.png' alt='App Logo' />
              </div>
            </a>
          </div>
          {/* END navbar header */}

          {/* START Left navbar */}
          <ul className='navbar-nav mr-auto flex-row'>
            <li className='nav-item'>
              {/* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
              <a href='' className='nav-link d-none d-md-block d-lg-block d-xl-block' onClick={this.toggleCollapsed}>
                <em className='fas fa-bars'></em>
              </a>
              {/* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
              <a href='' className='nav-link sidebar-toggle d-md-none' onClick={this.toggleAside}>
                <em className='fas fa-bars'></em>
              </a>
            </li>
            {/* START User avatar toggle */}
            <li className='nav-item d-none d-md-block'>
              <a className='nav-link' onClick={this.toggleUserblock}>
                <em className='icon-user'></em>
              </a>
            </li>
            {/* END User avatar toggle */}

            <li className='nav-item d-none d-md-block'>
              <ToggleFullscreen className='nav-link' />
            </li>
            <li className='nav-item'>
              <a className='nav-link' href='' onClick={this.toggleOffsidebar}>
                <em className='icon-notebook'></em>
              </a>
            </li>
            <li className='nav-item d-none d-md-block'>
              <a className='nav-link' onClick={this.handleDisconnect}>
                <em className={"fas fa-sign-out-alt"}></em>
              </a>
            </li>
          </ul>
          {/* END Left navbar */}
          {/* START Right Navbar */}
          <ul className='navbar-nav flex-row'>
            {/* Fullscreen (only desktops) */}
            <li className='nav-item d-none d-md-block'>
              <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center", flexDirection: "row", marginRight: 15 }}>
                <div style={{ fontWeight: "bold", color: "white", fontSize: 18 }}>{this.props.zoo.anonymousMode ? "Demo mode" : this.props.zoo.zooName}</div>
              </div>
            </li>

            {/* END Alert menu */}
            {/* START Offsidebar button */}

            {/* END Offsidebar menu */}
          </ul>
          {/* END Right Navbar */}

          {/* START Search form */}
          <form className='navbar-form' role='search' action='search.html'>
            <div className='form-group'>
              <input className='form-control' type='text' placeholder='Type and hit enter ...' />
              <div className='fa fa-times navbar-form-close' data-search-dismiss=''></div>
            </div>
            <button className='d-none' type='submit'>
              Submit
            </button>
          </form>
          {/* END Search form */}
        </nav>
        {/* END Top Navbar */}
      </header>
    )
  }
}

Header.propTypes = {
  actions: PropTypes.object,
  settings: PropTypes.object,
  zoo: PropTypes.object,
}

const mapStateToProps = (state) => ({ settings: state.settings, zoo: state.zoo })
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Header))
