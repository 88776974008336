import React from 'react';

import Header from './Header'
import Sidebar from './Sidebar'
import Offsidebar from './Offsidebar'
import Footer from './Footer'


export default class Base extends React.Component {

    async componentDidMount() {

    }
    render() {
        try {
            return (
                <div className="wrapper">
                    <Header />

                    <Sidebar />

                    <Offsidebar />

                    <section className="section-container">
                        {this.props.children}
                    </section>

                    <Footer />
                </div>
            )
        } catch (error) {
            console.log(error)
        }

    }
}

