const MenuAdmin = [
  {
    heading: "Akongo Welfare Manager",
  },

  {
    name: "Centre de pilotage",
    path: "DashboardAkongo",
    translate: "sidebar.nav.DASHBOARD",
  },
  {
    name: "Historiques des évaluations",
    path: "EvalHistory",
    translate: "sidebar.nav.HISTORY",
  },
  {
    name: "Timeline",
    path: "Timeline",
  },
  {
    name: "Bilan d'Activité",
    translate: "sidebar.nav.BILAN",
    path: "cirManagementView",
  },

  {
    heading: "Gestion du parc",
    translate: "sidebar.heading.PARC_MANAGEMENT",
  },

  {
    name: "Gestion des campagnes",
    path: "CampaignListView",
    translate: "sidebar.nav.CAMPAIGN_MANAGEMENT",
  },
  {
    name: "Gestion des individus",
    translate: "sidebar.nav.ANIMAL_MANAGEMENT",
    path: "specieAnimalsListView",
  },
  {
    name: "Gestion des groupes",
    translate: "sidebar.nav.GROUP_MANAGEMENT",
    path: "GroupListView",
  },
  {
    name: "Gestion des enclos",
    path: "EnclosureListView",
    translate: "sidebar.nav.ENCLOSURE_MANAGEMENT",
  },
  {
    name: "Gestion des enrichissements",
    translate: "sidebar.nav.ENRICHMENT_MANAGEMENT",
    submenu: [
      {
        name: "Suivi des mouvements",
        translate: "sidebar.nav.ENRICHMENT_HISTORY_LIST",
        path: "EnrichListHistory",
      },
      {
        name: "Gestion des enrichissements",
        path: "EnrichListView",
        translate: "sidebar.nav.ENRICHMENT_MANAGEMENT_LIST",
      },
    ],
  },
  {
    name: "Gestion des secteur",
    path: "SectorListView",
    translate: "sidebar.nav.SECTOR_MANAGEMENT",
  },
  {
    name: "Consulter les évaluations",
    path: "CampaignQuizzMonitoringView",
    translate: "sidebar.nav.EVALUATION_MONITORING",
  },

  {
    heading: "Ressources",
    translate: "sidebar.heading.RESSOURCES",
  },

  {
    name: "Consulter les questionnaires",
    path: "QuizzListViewReadOnly",
    translate: "sidebar.nav.QUIZZ_MONITORING",
  },

  {
    name: "Consulter les éthogrammes",
    path: "obsListViewReadOnly",
    translate: "sidebar.nav.ETHO_MONITORING",
  },

  {
    heading: "Mon compte AWS",
    translate: "sidebar.heading.MY_ACCOUNT",
  },
  {
    name: "Gérer mon compte",
    translate: "sidebar.nav.USER_SETTINGS",
    path: "userSettings",
  },
  {
    name: "Gérer mon établissement",
    translate: "sidebar.nav.ZOO_MANAGEMENT",
    path: "zooDetails",
  },
  {
    name: "Inviter des utilisateurs",
    translate: "sidebar.nav.USER_CREATION",
    path: "userCreation",
  },
]

export default MenuAdmin
