import generator from "generate-password"
import _ from "lodash"
import { FakeSiteGenerator } from "../components/Views/SiteManagement/demo/FakeSiteGen"
import { eulaVersion, getZooId, initAuth, initDb, initFunctions } from "../tools/conf"
const CryptoJS = require("crypto-js")
const db = initDb()

const LOCALSTORAGE_USERDATA = "userData"
const FakeSiteGen = new FakeSiteGenerator()

export class UserDatabaseConnector {
  async _getUsersList(zooId, concatAkongoAccess) {
    try {
      if (zooId == undefined) {
        zooId = await getZooId()
      }

      let userList = []
      let snapshot = await db.collection("users").where("zooId", "==", zooId).get()
      snapshot.forEach((doc) => {
        let data = doc.data()
        if (data.isDeleted) {
        } else {
          userList.push({
            label: `${data.userFirstname} ${data.userLastname}`,
            value: data.userId,
            ...data,
          })
        }
      })

      if (zooId !== "akongo") {
        userList = userList.filter((el) => !el.akongoStaff)
      }

      if (concatAkongoAccess) {
        try {
          let zooData = await (await db.collection("zoo").doc(zooId).get()).data()
          let { akongoStaff } = await this._getAkongoStaff()
          zooData.staffAccess.forEach((userId) => {
            let user = _.find(akongoStaff, (e) => e.userId === userId)
            userList.push(user)
          })
        } catch (error) {}
      }

      // debuplicate user list
      userList = _.uniqBy(userList, "userId")

      return userList
    } catch (error) {
      console.log(error)
    }
  }

  async _updateUserEula(userId) {
    try {
      await db.collection("users").doc(userId).update({
        eula: eulaVersion,
      })
      return {
        success: true,
      }
    } catch (error) {
      return {
        success: false,
      }
    }
  }

  async _getUserDetails(user) {
    let res
    if (_.has(user, "userId")) {
      res = await db.collection("users").doc(user.userId).get()
    } else {
      res = await db.collection("users").doc(user).get()
    }
    return res.data()
  }

  async _setPendingUser(newUser) {
    try {
      let cloud = await initFunctions()
      let password = generator.generate({
        length: 10,
        numbers: true,
      })
      newUser.tempPassword = password

      // de ICI

      let createUser = cloud.httpsCallable("createUser")
      let snap = await createUser({ ...newUser })
      let res = snap.data
      newUser = res.data

      if (res.success) {
        let localUser = await this._getUserData()
        let sendUserInvitation = cloud.httpsCallable("sendUserInvitation")

        if (localUser.data.userRight.value === "superAdmin") {
          let res2 = await sendUserInvitation({
            userData: newUser,
            zooId: newUser.userZoo.value,
            zooNameInviteFrom: "Akongo",
            peopleNameInviteFrom: localUser.data.userFirstname,
          })
          return { success: true, data: res2.data }
        } else {
          let res2 = await sendUserInvitation({
            userData: newUser,
            zooId: newUser.userZoo.value,
            zooNameInviteFrom: newUser.userZoo.label,
            peopleNameInviteFrom: localUser.data.userFirstname,
          })
          return {
            success: true,
            data: res2.data,
          }
        }
      } else {
        console.log("too")
        throw new Error()
      }
    } catch (error) {
      console.log("Ah bah non")
      console.log(error)
      return {
        success: false,
        data: error,
      }
    }
  }

  async storeUserDetails(user) {
    try {
      let userData = await this._getUserDetails(user.user.uid)

      await localStorage.setItem(
        LOCALSTORAGE_USERDATA,
        JSON.stringify({
          ...userData,
        })
      )

      await localStorage.setItem(
        "userRight",
        JSON.stringify({
          userRight: userData.userRight.value,
        })
      )

      return {
        success: true,
      }
    } catch (error) {
      return {
        success: false,
        data: error,
      }
    }
  }

  async _connectUser(login, password) {
    try {
      let auth = await initAuth()
      let user = await auth.signInWithEmailAndPassword(login, password)

      await this.storeUserDetails(user)
      return {
        success: true,
        data: user,
      }
    } catch (error) {
      console.log("Erreur de connexion")
      console.log(error)
      return {
        success: false,
        data: error,
      }
    }
  }

  async _getConnectedUser() {
    let auth = await initAuth()
    var user = await auth.currentUser

    if (user) {
      return {
        success: true,
        data: user,
      }
    } else {
      return {
        success: false,
      }
    }
  }

  async handleChangeRight(userId, newRight) {
    await db.collection("users").doc(userId).update({
      userRight: newRight,
    })
  }

  async deleteAccount(idOfWhoDeleted, userId) {
    await db.collection("users").doc(userId).update({
      isDeleted: true,
      deletedBy: idOfWhoDeleted,
    })
  }

  async handleEndOfPending(user) {
    await db.collection("users").doc(user.uid).update({
      tempPassword: null,
      userStatus: "active",
    })
  }

  async _updateUserPassword(login, prevPassword, newPassword) {
    try {
      let res = await this._connectUser(login, prevPassword)
      if (res.success) {
        let res2 = await this._getConnectedUser()
        let user = res2.data
        await user.updatePassword(newPassword)
        await this.handleEndOfPending(user)
        return {
          success: true,
          data: user,
        }
      } else {
        return {
          success: false,
          data: res.data,
        }
      }
    } catch (error) {
      return {
        success: false,
        data: error,
      }
    }
  }

  async _handleForgottenPassword(login) {
    try {
      let auth = await initAuth()
      await auth.sendPasswordResetEmail(login)
      return {
        success: true,
      }
    } catch (error) {
      return {
        success: false,
      }
    }
  }

  async _handleRememberMe(login, password) {
    var data = {
      login: login,
      password: password,
    }

    // Encrypt
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), "gwen1337secu").toString()
    localStorage.setItem("rememberMeCrypt", ciphertext)
  }

  async _disconnectUser() {
    try {
      let auth = await initAuth()
      auth.signOut()
      return { success: true }
    } catch (error) {
      console.log(error)
    }
  }

  async _RemoveRememberMe() {
    localStorage.removeItem("rememberMe")
    localStorage.removeItem("rememberMeCrypt")
  }

  async _getEulaStatus(userId) {
    let user = await this._getUserDetails(userId)
    if (_.has(user, "eula")) {
      if (user.eula === eulaVersion) {
        return true
      }
    }
    return false
  }

  async _getUserRight() {
    let userRight = localStorage.getItem("userRight")
    if (userRight !== null) {
      return {
        success: true,
        data: JSON.parse(userRight),
      }
    } else {
      return {
        success: false,
      }
    }
  }

  async _updateTempUserData(userData) {
    // utilisé pour changer de zoo temporairement de zoo pour un admin
    try {
      await localStorage.setItem(
        LOCALSTORAGE_USERDATA,
        JSON.stringify({
          ...userData,
        })
      )
      return {
        success: true,
        data: userData,
      }
    } catch (error) {
      return {
        success: false,
        data: error,
      }
    }
  }

  async _updateUserData(userData, adminEdit, prevZooID) {
    let db = initDb()
    try {
      userData = {
        ...userData,
        zooId: userData.userZoo.value,
      }
      await db.collection("users").doc(userData.userId).update(userData)
      if (adminEdit === true) {
        await localStorage.setItem(
          LOCALSTORAGE_USERDATA,
          JSON.stringify({
            ...userData,
          })
        )
      } else {
        if (prevZooID !== userData.zooId) {
          await localStorage.setItem(
            LOCALSTORAGE_USERDATA,
            JSON.stringify({
              ...userData,
            })
          )
        }
      }

      return {
        success: true,
        data: userData,
      }
    } catch (error) {
      return {
        success: false,
        data: error,
      }
    }
  }

  async _getUserData(userId, online) {
    if (online) {
      let userData = await (await db.collection("users").doc(userId).get()).data()
      await localStorage.setItem(
        "userData",
        JSON.stringify({
          ...userData,
        })
      )
      return {
        data: userData,
        success: true,
      }
    }

    if (userId === undefined) {
      let userData = localStorage.getItem("userData")
      if (userData !== null) {
        return {
          success: true,
          data: JSON.parse(userData),
        }
      } else {
        return {
          success: false,
        }
      }
    } else {
      let userData = await (await db.collection("users").doc(userId).get()).data()
      return {
        data: userData,
        success: true,
      }
    }
  }

  async _checkRememberMe() {
    try {
      let rememberMe = localStorage.getItem("rememberMe")
      let rememberMeCrypt = localStorage.getItem("rememberMeCrypt")
      if (rememberMe !== null) {
        return {
          success: true,
          data: JSON.parse(rememberMe),
        }
      } else if (rememberMeCrypt !== null) {
        var bytes = CryptoJS.AES.decrypt(rememberMeCrypt, "gwen1337secu")
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
        console.log("autoLogin")
        return {
          success: true,
          data: decryptedData,
        }
      } else {
        return {
          success: false,
        }
      }
    } catch (error) {
      console.log(error)
      return {
        success: false,
      }
    }
  }

  async _getAccessGrantedZooFromUser(userId) {
    let snap = await db.collection("zoo").where("staffAccess", "array-contains", userId).get()
    let zoo = []
    snap.forEach((el) => {
      let data = el.data()
      data.label = data.zooName
      data.value = data.zooId
      zoo.push(data)
    })
    return zoo
  }

  async _getAkongoStaff() {
    let snap = await db.collection("users").where("akongoStaff", "==", true).get()
    let akongoStaff = []
    snap.forEach((el) => {
      akongoStaff.push(el.data())
    })

    let manager = akongoStaff.filter((el) => el.akongoRank === "manager" || el.akongoRank === "board")

    return {
      manager: manager,
      akongoStaff: akongoStaff,
    }
  }
}
