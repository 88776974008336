import i18n from "i18next"

// import LanguageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from "react-i18next"
import enTranslations from "./locales/en/translations.json"
import frTranslations from "./locales/fr/translations.json"

i18n
  // .use(LanguageDetector)
  .use(reactI18nextModule)
  .init({
    fallbackLng: "fr",
    resources: {
      en: {
        translations: enTranslations,
      },
      fr: {
        translations: frTranslations,
      },
    },
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
    debug: true,
    react: {
      wait: true,
    },
  })

export default i18n
