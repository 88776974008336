const MenuPedago = [
  {
    heading: "Akongo Welfare Manager",
    translate: "sidebar.heading.HEADER",
  },
  {
    name: "Gestion des campagnes",
    path: "CampaignListView",
    translate: "sidebar.nav.CAMPAIGN_MANAGEMENT",
  },
  {
    name: "Gestion des individus",
    translate: "sidebar.nav.ANIMAL_MANAGEMENT",
    path: "specieAnimalsListView",
  },
  {
    name: "Consulter les évaluations",
    path: "CampaignQuizzMonitoringView",
    translate: "sidebar.nav.EVALUATION_MONITORING",
  },
  {
    heading: "Mon compte AWS",
    translate: "sidebar.heading.MY_ACCOUNT",
  },
  {
    name: "Gérer mon compte",
    translate: "sidebar.nav.USER_SETTINGS",
    path: "userSettings",
  },
]

export default MenuPedago
