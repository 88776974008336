import moment from "moment"

export function getAge(birthday) {
  if (birthday !== undefined) {
    birthday = _bodCompatibility(birthday)

    let year = birthday.split("/").pop()
    let month = birthday.split("/")[0]
    const age = moment().diff(year + "-" + month, "years")
    return age.toString() + " ans"
  } else {
    return "N/C"
  }
}

export function _bodCompatibility(birthday) {
  try {
    if (birthday.length === 8) {
      let temp = moment(birthday, "DD/MM/YY")
      birthday = temp.format("MM/YYYY")
    }
  } catch (error) {
    console.log(error)
  }

  return birthday
}

export function arraymove(arr, fromIndex, toIndex) {
  var element = arr[fromIndex]
  arr.splice(fromIndex, 1)
  arr.splice(toIndex, 0, element)
  return arr
}

export function sortQuestionInNumericalOrder(list) {
  list.sort(function (a, b) {
    var aName = a.baseRef.replace(/[^a-zA-Z ]/g, "")

    if (aName.length > 1) {
      console.log(a)
    }

    let valA = parseInt(a.baseRef.replace(/[^0-9,.]+/g, ""))
    let valB = parseInt(b.baseRef.replace(/[^0-9,.]+/g, ""))
    if (valA < valB) {
      return -1
    }
    if (valA > valB) {
      return 1
    }
    return 0
  })

  return list
}

export function sortQuestions(array) {
  let arr = []

  let cat1 = array.filter((el) => el.category.value === 1)
  let cat2 = array.filter((el) => el.category.value === 2)
  let cat3 = array.filter((el) => el.category.value === 3)
  let cat4 = array.filter((el) => el.category.value === 4)

  cat1.sort(function (a, b) {
    try {
      let aName = parseInt(a.label.substring(1))
      let bName = parseInt(b.label.substring(1))

      if (aName > bName) return 1
      if (aName < bName) return -1
      return 0
    } catch (error) {
      console.log(error)
      return 0
    }
  })

  cat1.map((el) => {
    arr.push(el)
  })

  cat2.sort(function (a, b) {
    try {
      let aName = parseInt(a.label.substring(1))
      let bName = parseInt(b.label.substring(1))

      if (aName > bName) return 1
      if (aName < bName) return -1
      return 0
    } catch (error) {
      console.log(error)
      return 0
    }
  })

  cat2.map((el) => {
    arr.push(el)
  })

  cat3.sort(function (a, b) {
    try {
      let aName = parseInt(a.label.substring(1))
      let bName = parseInt(b.label.substring(1))

      if (aName > bName) return 1
      if (aName < bName) return -1
      return 0
    } catch (error) {
      console.log(error)
      return 0
    }
  })

  cat3.map((el) => {
    arr.push(el)
  })

  cat4.sort(function (a, b) {
    try {
      let aName = parseInt(a.label.substring(1))
      let bName = parseInt(b.label.substring(1))

      if (aName > bName) return 1
      if (aName < bName) return -1
      return 0
    } catch (error) {
      console.log(error)
      return 0
    }
  })

  cat4.map((el) => {
    arr.push(el)
  })

  return arr
}

export function sortQuestions2(array) {
  let arr = []

  let cat1 = array.filter((el) => el.category.value === 1)
  let cat2 = array.filter((el) => el.category.value === 2)
  let cat3 = array.filter((el) => el.category.value === 3)
  let cat4 = array.filter((el) => el.category.value === 4)

  let i = 1
  let errors = {}

  while (i < 250) {
    try {
      let res = cat1.filter((el) => {
        try {
          return el.baseRef.substring(1) === i.toString()
        } catch (error) {
          errors = {
            ...errors,
            [el.questionId]: el,
          }
        }
      })
      try {
        cat1 = cat1.filter((el) => el.baseRef.substring(1) !== i.toString())
      } catch (error) {
        console.log("too")
      }

      res.map((el) => {
        if (el.questionStage === "BASE") {
          let base = el // unique
          arr.push(base)
          let orders = res.filter((orderItem) => orderItem.questionStage === "ORDER")
          orders.map((order) => {
            arr.push(order)
            let families = res.filter((familyItem) => familyItem.questionStage === "FAMILY" && familyItem.orderId === order.orderId)
            families.map((family) => {
              arr.push(family)
              let species = res.filter((specieItem) => specieItem.questionStage === "SPECIES" && specieItem.familyId === family.familyId)
              arr = [...arr, ...species]
            })
          })
        }
      })
      i = i + 1
    } catch (error) {
      i = 1000
    }
  }
  arr = [...arr, ...cat1]

  // START PART 2
  i = 1
  while (i < 250) {
    try {
      let res = cat2.filter((el) => {
        try {
          return el.baseRef.substring(1) === i.toString()
        } catch (error) {
          errors = {
            ...errors,
            [el.questionId]: el,
          }
        }
      })
      try {
        cat2 = cat2.filter((el) => el.baseRef.substring(1) !== i.toString())
      } catch (error) {
        console.log("too")
      }

      res.map((el) => {
        if (el.questionStage === "BASE") {
          let base = el // unique
          arr.push(base)
          let orders = res.filter((orderItem) => orderItem.questionStage === "ORDER")
          orders.map((order) => {
            arr.push(order)
            let families = res.filter((familyItem) => familyItem.questionStage === "FAMILY" && familyItem.orderId === order.orderId)
            families.map((family) => {
              arr.push(family)
              let species = res.filter((specieItem) => specieItem.questionStage === "SPECIES" && specieItem.familyId === family.familyId)
              arr = [...arr, ...species]
            })
          })
        }
      })
      i = i + 1
    } catch (error) {
      i = 1000
    }
  }
  arr = [...arr, ...cat2]

  // START PART 3
  i = 1
  while (i < 250) {
    try {
      let res = cat3.filter((el) => {
        try {
          return el.baseRef.substring(1) === i.toString()
        } catch (error) {
          errors = {
            ...errors,
            [el.questionId]: el,
          }
        }
      })
      try {
        cat3 = cat3.filter((el) => el.baseRef.substring(1) !== i.toString())
      } catch (error) {
        console.log("too")
      }

      res.map((el) => {
        if (el.questionStage === "BASE") {
          let base = el // unique
          arr.push(base)
          let orders = res.filter((orderItem) => orderItem.questionStage === "ORDER")
          orders.map((order) => {
            arr.push(order)
            let families = res.filter((familyItem) => familyItem.questionStage === "FAMILY" && familyItem.orderId === order.orderId)
            families.map((family) => {
              arr.push(family)
              let species = res.filter((specieItem) => specieItem.questionStage === "SPECIES" && specieItem.familyId === family.familyId)
              arr = [...arr, ...species]
            })
          })
        }
      })
      i = i + 1
    } catch (error) {
      i = 1000
    }
  }
  arr = [...arr, ...cat3]

  // START PART 3
  i = 1
  while (i < 250) {
    try {
      let res = cat4.filter((el) => {
        try {
          return el.baseRef.substring(1) === i.toString()
        } catch (error) {
          errors = {
            ...errors,
            [el.questionId]: el,
          }
        }
      })
      try {
        cat4 = cat4.filter((el) => el.baseRef.substring(1) !== i.toString())
      } catch (error) {
        console.log("too")
      }

      res.map((el) => {
        if (el.questionStage === "BASE") {
          let base = el // unique
          arr.push(base)
          let orders = res.filter((orderItem) => orderItem.questionStage === "ORDER")
          orders.map((order) => {
            arr.push(order)
            let families = res.filter((familyItem) => familyItem.questionStage === "FAMILY" && familyItem.orderId === order.orderId)
            families.map((family) => {
              arr.push(family)
              let species = res.filter((specieItem) => specieItem.questionStage === "SPECIES" && specieItem.familyId === family.familyId)
              arr = [...arr, ...species]
            })
          })
        }
      })
      i = i + 1
    } catch (error) {
      i = 1000
    }
  }
  arr = [...arr, ...cat4]

  return arr
}

export function generateTaxonomyList(species, order, family) {
  let taxList = []
  order.map((el) => {
    taxList.push({
      ...el,
      type: "ORDER",
    })
  })
  family.map((el) => {
    taxList.push({
      ...el,
      type: "FAMILY",
    })
  })
  species.map((el) => {
    taxList.push({
      ...el,
      type: "SPECIES",
    })
  })
  return taxList
}
