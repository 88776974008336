const MenuAdmin = [
  {
    heading: "Akongo Welfare Manager",
  },
  {
    heading: "Ressources",
    translate: "sidebar.heading.RESSOURCES",
  },

  {
    name: "Consulter les questionnaires",
    path: "QuizzListViewReadOnly",
    translate: "sidebar.nav.QUIZZ_MONITORING",
  },

  {
    name: "Consulter les éthogrammes",
    path: "obsListViewReadOnly",
    translate: "sidebar.nav.ETHO_MONITORING",
  },

  {
    heading: "Mon compte AWS",
    translate: "sidebar.heading.MY_ACCOUNT",
  },
  {
    name: "Gérer mon compte",
    translate: "sidebar.nav.USER_SETTINGS",
    path: "userSettings",
  },
]

export default MenuAdmin
